[data-v-cde003b0] .el-dialog__body {
  text-align: left !important;
}
[data-v-cde003b0] .el-dialog__header {
  text-align: left;
  border-bottom: solid 1px #dcdfe6;
}
[data-v-cde003b0] .el-tab-pane {
  padding-right: 15px;
}
[data-v-cde003b0] .el-tabs__content {
  padding-left: 10px;
}
[data-v-cde003b0] .el-collapse-item__header {
  background-color: #f1f6fc;
  padding-left: 8px;
}
[data-v-cde003b0] .el-collapse-item__content {
  padding: 20px 0 10px;
}
[data-v-cde003b0] .el-collapse {
  border-bottom: none;
}
.trigger[data-v-cde003b0] .el-collapse-item__wrap {
  border-bottom: none;
}
.normal-list-row[data-v-cde003b0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
[data-v-cde003b0] .el-col {
  text-align: center;
}
[data-v-cde003b0] .el-row {
  margin-bottom: 10px;
}
.top-operation[data-v-cde003b0] {
  width: 100%;
  margin-bottom: 10px;
  margin-top: -10px;
  text-align: right;
}
